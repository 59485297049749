// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
const images = require.context("../images", true);
const imagePath = (name) => images(name, true);

import "jquery.initialize";
import "lazysizes/lazysizes.min";
import "unpoly/dist/unpoly.min.js";

import ExternalLinks from "./external_links";
import { pushToDataLayer } from "./ga4/ga4_event.js";
import Gallery from "./gallery";
import Instagram from "../javascripts/instagram";
import OneTrust from "../javascripts/one_trust";
import PersistentEmailCTA from "./persistent_email_cta";
import ProductGrid from "../../components/product_grid/product_grid";
import Rails from "rails-ujs";
import Search from "./search/search";
import Video from "./video";
import EmailSignUpCTA from "./email_sign_up_cta.js";
import Honeypot from "./honeypot";
import "/app/services/article_formatter/render/widgets/cards/product_card.js";

import "../javascripts/geniuslink.js"
import "../javascripts/admin_bar.js";
import "../javascripts/navigation.js";
import "../javascripts/skimlinks.js";
import MainNavbar from "./components/navigation/main_navbar.js";
import MainMenu from "./components/navigation/main_menu/main_menu.js";
import SearchMenu from "./components/navigation/search_menu/search_menu.js";
Rails.start();

window.pushToDataLayer = pushToDataLayer;
window.mainNavbar = new MainNavbar();
window.mainMenu = new MainMenu();
window.searchMenu = new SearchMenu();

$(() => {
  ExternalLinks.run();
  Gallery.run();
  Instagram.run();
  OneTrust.run();
  PersistentEmailCTA.run();
  Search.run();
  EmailSignUpCTA.run();
  Honeypot.run();
});

//----------------------------------------------------------------------------------------------------------------
// the following javascript is dynamically ran depending on if components that use it are rendered in the current page
// see ruby class Js::Javascript
$(() => {
  const runners = {
    video: Video,
    product_grid: ProductGrid
  };

  window.js_libs_to_include.forEach((lib_name) => runners[lib_name].run());
});
//----------------------------------------------------------------------------------------------------------------
